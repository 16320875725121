import React, { useContext, useEffect, useState, lazy, Suspense } from "react";
import Categories from "../Components/HomeComponent/Categories";
import EventTimeline from "../Components/HomeComponent/EventTimeline";
import Gallery from "../Components/HomeComponent/Gallery";
import Ourprocess from "../Components/HomeComponent/Ourprocess";
import Sponsers from "../Components/HomeComponent/Sponsers";
import Support from "../Components/HomeComponent/Support";
import TextSlider from "../Components/HomeComponent/TextSlider";
import Whoweare from "../Components/HomeComponent/Whoweare";
import { motion } from "framer-motion";
import SponserBIg from "../Components/HomeComponent/SponserBIg";
import { ScrolltoTop } from "../Utility";
import { AuthContext } from "../ContextApi/AppContext";
import { useNavigate } from "react-router";
import useFetchData from "../CustomHooks/useFetchData";

// Lazy Components 
const Jury = lazy(() => import("../Components/HomeComponent/Jury"));


function Home() {
    const { lang, setPopupState } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);



    // const {data: jury, loading, error, refetch } =  useFetchData("jury-members", 'get', [], '1', true ,true)


    const navigate = useNavigate();

    document.title = "Homepreneur Awards 2023";

    const handleLoading = () => {
        console.log('working')
        setIsLoading(false);
    }

    useEffect(() => {
        const PageState = document.readyState;
        if (PageState === 'complete') {
            handleLoading()
        } else {

            window.addEventListener("load", handleLoading);
        }
    }, [])

    useEffect(() => {
        ScrolltoTop();
    }, []);



    const TitleData = [
        {
            title: "First of its kind",
        },
        {
            title: "Recognizing women who build their entrepreneurial dream from home",
        },
        {
            title: "After 5 tremendous seasons Successfully entering into season 6-Homepreneur Awards",
        },
        {
            title: "First of its kind",
        },
        {
            title: "Recognizing women who build their entrepreneurial dream from home",
        },
        {
            title: "After 5 tremendous seasons Successfully entering into season 6-Homepreneur Awards",
        },
    ];

    const partnersData = [
        {
            icon: "assets/images/partners/prt1.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt4.webp",
            // title: 'Technology Partner',
        },
        {
            icon: "assets/images/partners/prt2.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt17.webp",
            // title: 'Reach Partner',
        },

        {
            icon: "assets/images/partners/prt1.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt4.webp",
            // title: 'Technology Partner',
        },
        {
            icon: "assets/images/partners/prt2.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt17.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt18.jpeg",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt19.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt20.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt21.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt22.webp",
            // title: 'Reach Partner',
        },
        {
            icon: "assets/images/partners/prt23.png",
            // title: 'Reach Partner',
        },


    ];

    const SponsersData = [
        {
            icon: "assets/images/sponsers/new1.webp",
            title: "Title Sponsor",
        },
        {
            icon: "assets/images/sponsers/sp2.png",
            title: "Associate Sponsors",
        },
        {
            icon: "assets/images/sponsers/sp3.png",
            title: "Associate Sponsors",
        },
        {
            icon: "assets/images/sponsers/new1.webp",
            title: "Associate Sponsors",
        },
        {
            icon: "assets/images/sponsers/new3.webp",
            title: "Associate Sponsors",
        },
        {
            icon: "assets/images/sponsers/sp5.png",
            title: "Powered by",
        },
        {
            icon: "assets/images/sponsers/sp6.png",
            title: "Powered by",
        },
    ];

    const SponsersDatanew = [
        {
            title: "Title Sponsor",
            icons: [
                {
                    img: "assets/images/sponsers/new1.webp",
                },
            ],
        },

        {
            title: "Powered by",
            icons: [
                {
                    img: "assets/images/sponsers/new3.webp",
                },

            ],
        },

        {
            title: "Co-initiated by",
            icons: [
                {
                    img: "assets/images/sponsers/new2.webp",
                },

            ],
        },

        {
            title: "Associate Sponsor",
            icons: [
                {
                    img: "assets/images/sponsers/new4.webp",
                },

            ],
        },
    ];

    const PartnersDatanew = [
        {
            title: "Reach Partner",
            icons: [
                {
                    img: "assets/images/partners/prt1.webp",
                },
                {
                    img: "assets/images/partners/prt2.webp",
                },
                {
                    img: "assets/images/partners/prt3.png",
                },
                {
                    img: "assets/images/partners/prt5.png",
                },
                {
                    img: "assets/images/partners/prt15.png",
                },
                {
                    img: "assets/images/partners/prt16.png",
                },
            ],
        },

        {
            title: "Technology Partner",
            icons: [
                {
                    img: "assets/images/partners/prt4.webp",
                },
            ],
        },
        {
            title: "Student Edition Partner",
            icons: [
                {
                    img: "assets/images/partners/prt6.png",
                },
            ],
        },
        {
            title: "Veg-Food Partner",
            icons: [
                {
                    img: "assets/images/partners/prt7.png",
                },
            ],
        },

        {
            title: "Institutional Reach Partner",
            icons: [
                {
                    img: "assets/images/partners/prt8.png",
                },
            ],
        },
        {
            title: "Camera Partner",
            icons: [
                {
                    img: "assets/images/partners/prt9.png",
                },
                {
                    img: "assets/images/partners/prt10.png",
                },
            ],
        },
        {
            title: "Non-Veg Partner",
            icons: [
                {
                    img: "assets/images/partners/prt11.png",
                },
            ],
        },
        {
            title: "Tea Partner",
            icons: [
                {
                    img: "assets/images/partners/prt12.png",
                },
            ],
        },
        {
            title: "Creche Partner",
            icons: [
                {
                    img: "assets/images/partners/prt13.png",
                },
            ],
        },
        {
            title: "Youtube Partner",
            icons: [
                {
                    img: "assets/images/partners/prt14.png",
                },
            ],
        },
    ];


    if (isLoading) return <LoadingScreen />

    return (
        <>
            <div className="brand-max-width mx-auto relative  ">
                {/* <video

                    className="h-screen w-screen object-cover lg:Object-fill xl:object-fill lg:object-left-top xl:object-left-top"
                    src="assets/video/herosection.mp4"
                    autoPlay={true}
                    muted
                    loop></video> */}
                <img className="object-cover" src="/assets/tq-banner.jpg" alt="" />
                <div className="inset-0 absolute    gap-10 w-full">
                    <div className="inset-0 absolute   flex flex-row items-center justify-start">

                        <div className="hidden flex flex-col items-center  lg:items-start xl:items-start justify-start lg:pl-[50px] mx-5 xl:pl-[50px] gap-2 w-full md:w-full lg:w-1/2  xl:w-1/2 overflow-hidden">
                            <div className="">
                                <TextSlider data={TitleData} />
                            </div>
                            {/* <Timer date={new Date("2023-05-16T00:00:00")} /> */}
                            <div className="flex items-center gap-5">
                                <motion.img
                                    initial={{ x: 0 }}
                                    animate={{ x: 0 }}
                                    transition={{ type: "spring", stiffness: 150, delay: 1 }}
                                    className=" hidden lg:block xl:block w-[300px] bg-white/0 p-2 rounded-lg h-auto bottom-24 left-5"
                                    src="assets/images/logo-hd.png"
                                    alt=""
                                />

                            </div>

                            <div className="flex gap-2 hidden">
                                <button
                                    onClick={() => navigate("/register")}
                                    className="capitalize xl:hidden lg:hidden text-white bg-primary px-4 py-3 rounded-md active:opacity-25 ">
                                    {lang === "english" ? "Homepreneur awards 2023" : "சுயசக்தி விருதுகள் 2023"}
                                    <br />
                                    <small>{lang === "english" ? "Apply Now" : "விண்ணப்பம் செய்க"}</small>
                                </button>
                                <button
                                    onClick={() => navigate("/studentform")}
                                    className="capitalize xl:hidden lg:hidden text-white bg-primary px-4 py-3 rounded-md active:opacity-25 ">
                                    {lang === "english"
                                        ? "Homepreneur awards (Student Edition)"
                                        : "சுயசக்தி விருதுகள் (மாணவர் பதிவு)"}
                                    <br />
                                    <small>{lang === "english" ? "Apply Now" : "விண்ணப்பம் செய்க"}</small>
                                </button>
                            </div>
                        </div>
                        {/* <div
                            className="absolute  flex-col items-center hidden md:hidden lg:block xl:flex right-0"
                            style={{ backgroundImage: "url('assets/gif/bg1.gif')" }}>
                            <motion.img
                                initial={{ x: 0 }}
                                animate={{ x: 0 }}
                                transition={{ type: "spring", stiffness: 150, delay: 1 }}
                                className=" hidden lg:block xl:block w-[200px] bg-white/0 p-2 rounded-lg h-auto top-10 right-5"
                                src="assets/images/brandavt-brnd.png"
                                alt=""
                            />
                            <img
                                className=""
                                src="assets/images/honor-sheild.png"
                                alt=""
                            />
                            <img
                                className=""
                                src="assets/svg/icons/season.svg"
                                alt=""
                            />
                        </div> */}
                    </div>
                </div>
            </div>
            <SponserBIg />
            <Ourprocess />
            <EventTimeline />

            <Suspense fallback={<h1>Still Loading…</h1>}>
                <Jury />
            </Suspense>

            <Categories title={lang === "english" ? "Categories" : "பிரிவுகள்"} />
            <Support />
            <Gallery />
            <Sponsers
                reverse={true}
                title={lang === "english" ? "our sponsors" : "ஸ்பான்சர்கள்"}
                partners={SponsersData}
                NewData={SponsersDatanew}
                oldDesign={false}
            />
            <Sponsers
                reverse={false}
                title={lang === "english" ? "our partners" : "பார்ட்னர்ஸ்"}
                partners={partnersData}
                NewData={PartnersDatanew}
                oldDesign={true}
            />
            <Whoweare />
        </>
    );
}

export default Home;


const LoadingScreen = () => {
    return (
        <>
            <>
                <div className='fixed inset-0 bg-heading/50 backdrop-blur-md w-full h-full  z-50 flex justify-center items-center'>
                    <div className='flex justify-center items-center flex-col gap-2'>
                        <img className='w-20 animate-spin' src="assets/svg/icons/loader.svg" alt="" />
                        <p className='text-primary font-bold capitalize'>Loading...</p>
                    </div>
                </div>
            </>
        </>
    )
}