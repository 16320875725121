import React from 'react'

function PageNotFound() {
  return (
   <>
   <div className='h-screen w-screen grid place-items-center'>
    <p className='text-4xl'>404 Page Not Found</p>

   </div>
   </>
  )
}

export default PageNotFound